<template>
	<div class="contant">
		<banner></banner>
		<div class="mainCon">
			<div class="pxhdCon">
				<img src="../../assets/img/pc/pxhd.png" alt="" />
			</div>
			<div class="topCon" @click="toLive(data.type, data.course_id)">
				<img :src="data.url" class="img" alt="">
				<div class="titCon">
					<span class="state" v-if="this.type == 0" style="background: #FF0000;">课程未开始</span>
					<span class="state" v-if="this.type == 1" style="background: #2dd296;">直播中</span>
					<span class="state" v-if="this.type == 3">回放中</span>
					<span class="state" v-if="this.type == -1" style="background: #FF0000;">课程已结束</span>
					<span class="tit"><span>{{ data.course_name }}</span><span
							class="zj">授课专家：{{ data.speaker }}</span></span>
				</div>
			</div>
			<div class="letitMsg">
				<div class="titleCon">
					<img src="../../assets/img/pc/video.png" class="icon" alt="">
					<span class="txt">共有{{total}}节课程</span>
				</div>
				<img src="../../assets/img/pc/more.png" class="more" alt="">
			</div>
			<!-- 课程列表 -->
			<div class="videoCon">
				<videoItem :item="item" v-for="(item, index) in list" :key="item.course_id"  @click="toLive(item.type, item.course_id)"></videoItem>
				<pages :page="page" :page-size="pageSize" :total="total" @change="pageChange"></pages>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import banner from "@/components/pc/banner"
	import foot from "@/components/pc/foot"
	import videoItem from "@/components/pc/videoItem"
	import Pages from '@/components/pages'
	import {
		sendWebsocket,
		closeWebsocket,
		websocketSendMess
	} from '@/utlis/websocket.js'
	export default {
		components: {
			banner,
			foot,
			videoItem,
			Pages
		},
		data() {
			return {
				// 置顶数据
				data: {},
				type: 0,
				// 数据列表
				list: [],
				aList: [],
				// 分页
				page: 1,
				pageSize: 9,
				total: 0
			}
		},
		mounted() {
			// 置顶数据
			this.jntsHome()
			// 数据列表
			this.init()
			this.requstWs()
		},
		methods: {
			// 置顶数据
			async jntsHome() {
				const res = await this.$api.jntsHome();
				this.data = res.data ? res.data : {};
				if (this.data !== {}) {
					this.type = this.data.type;
				}
			},
			// 查看详情
			toLive(type, course_id) {
				console.log(type, course_id)
				if (type == 0) {
					this.$toast.fail("课程未开始")
					return;
				}
				if (type == -1) {
					this.$toast.fail("课程已结束")
					return;
				}
				this.$router.push('/pc/video?id=' + course_id);
			},
			// 数据列表
			async init() {
				const res = await this.$api.jntsList({
					page: this.page,
					pageSize: this.pageSize
				});
				this.list = res.data.list;
				this.total = res.data.totalRow;
			},
			// 分页
			pageChange(page) {
				this.page = page;
				this.init();
			},
			// ws连接成功，后台返回的ws数据，组件要拿数据渲染页面等操作
			wsMessage(data) {
				this.type = data.data;
				this.data.type = data.data;
			},
			// ws连接失败，组件要执行的代码
			wsError() {
				console.log('ws连接失败')
			},
			// ws连接成功，组件要执行的代码
			succeed(e) {
				console.log('ws连接成功')
			},
			requstWs() {
				// 防止用户多次连续发送，所以要先关闭上次的ws请求。
				closeWebsocket()
				// 跟后端协商，需要什么参数数据给后台
				// 发起ws请求
				sendWebsocket(`${this.GLOBAL.socketUrl}`, {}, this.wsMessage, this.wsError,this.succeed)
			}
		},
		beforeDestroy() {
			closeWebsocket()
		}
	}
</script>

<style scoped lang="less">
	.contant {
		width: 100%;
		min-width: 1200px;
		background: url('../../assets/img/pc/bg.jpg') no-repeat;
		background-size: cover;
		.mainCon {
			width: 1200px;
			margin: 0 auto;
			min-height: 500px;
			.pxhdCon{
				width: 100%;
				img{
					width: 100%;
				}
			}

			.topCon {
				width: 100%;
				height: 483px;
				// height: 647px;
				// margin-top: 60px;
				margin-bottom: 33px;
				position: relative;
				cursor: pointer;
				overflow: hidden;

				.img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				.titCon {
					width: 100%;
					height: 140px;
					background: rgba(0, 0, 0, .3);
					position: absolute;
					left: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					padding: 0 23px;
					box-sizing: border-box;

					.state {
						padding: 3px 10px;
						background-color: #D18B28;
						border-radius: 5px;
						color: #fff;
						font-size: 28px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 10px;
					}

					.tit {
						width: 960px;
						font-size: 32px;
						color: #fff;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;

						.zj {
							margin-left: 50px;
						}
					}
				}
			}

			.letitMsg {
				width: 100%;
				margin-bottom: 65px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.titleCon {
					width: 1145px;
					height: 39px;
					background: url('../../assets/img/pc/jb.png') no-repeat;
					background-size: 100% 100%;
					display: flex;
					align-items: center;

					.icon {
						width: 47px;
						margin-left: 14px;
					}

					.txt {
						font-size: 26px;
						color: #fff;
						margin-left: 10px;
					}
				}

				.more {
					width: 38px;
					height: 38px;
				}
			}

			// 课程列表
			.videoCon {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
			}
		}
	}
</style>